<template>
  <modal
    :width="1400"
    :adaptive="true"
    class="newEditBannerModal CEModal modal-scroll-bar"
    name="NewEditBannerModal"
  >
    <h4 class="weight-700">{{ $t('ADS_MANAGEMENT.NEW_BANNER_EDIT') }}</h4>
    <div class="form-block m-t-10">
      <div class="m-b-10 px7 w-100 m-publication_input">
        <label>{{ $t('ADS_MANAGEMENT.NAME_PUBLICATION') }}</label>
        <input type="text" class="input" v-model="bannerData.name" />
      </div>
      <div class="m-b-10 px7 w-100 m-status_input">
        <label>{{ $t('REPORT_SALES.STATUS') }}</label>
        <vue-select
          :options="status.options"
          class="input cus-select"
          v-model="status.selected"
        ></vue-select>
      </div>
      <div class="m-b-10 px7 w-100 m-bounce-num_input">
        <label>{{ $t('ADS_MANAGEMENT.NUMBER_OF_BOUNCES') }}</label>
        <div class="d-flex">
          <input type="text" class="input" v-model="bannerData.num_bounce" />
          <div class="px7">
            <div class="bounce-btn">
              <img src="/assets/img/icons/chevron-up_sm.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="m-b-10 px7 w-100 m-page_input">
        <label>{{ $t('ADS_MANAGEMENT.PAGE') }}</label>
        <vue-select
          :options="test.options"
          class="input cus-select"
          v-model="test.selected"
        ></vue-select>
      </div>
    </div>
    <div class="form-block m-t-10">
      <div class="m-b-10 px7 w-100 m-advertiser_input">
        <label>{{ $t('ADS_MANAGEMENT.ADVERTISER') }}</label>
        <vue-select
          :options="test.options"
          class="input cus-select"
          v-model="test.selected"
        ></vue-select>
      </div>
      <div class="m-b-10 px7 w-100">
        <label>{{ $t('ADS_MANAGEMENT.LANGUAGE') }}</label>
        <vue-select
          :options="languageDropdown.options"
          class="input cus-select cus-multi-dropdown"
          v-model="bannerData.language"
          multiple
        ></vue-select>
      </div>
      <div class="m-b-10 px7 w-100 m-advertiser_input">
        <label>{{ $t('DESTINATION.ORDER') }}</label>
        <input type="text" class="input" v-model="bannerData.order" />
      </div>
      <div class="m-b-10 px7 w-100 m-publication_input">
        <label>{{ $t('ADS_MANAGEMENT.LINK_TO_PAGE') }}</label>
        <input type="text" class="input" v-model="bannerData.link_url" />
      </div>
    </div>
    <div class="m-b-10 px7 w-100 m-advertiser_input">
      <label>{{ $t('TOUR_PLACES.LOCATION') }}</label>
      <vue-select
        :options="locationDropdown.options"
        class="input cus-select cus-multi-dropdown"
        v-model="locationDropdown.selected"
        multiple
      ></vue-select>
    </div>
    <div class="px7 m-t-10">
      <label>{{ $t('DESTINATION.TAGS') }}</label>
      <div class="tag-block">
        <TagsBlock :tags='tags' :selectdTags='bannerData.tags || []'></TagsBlock>
      </div>
    </div>
    <div class="form-block m-t-20">
      <div class="m-b-10 px7 w-100 m-advertiser_input">
        <label>{{ $t('ADS_MANAGEMENT.CHOOSE_POINT_RESELLER') }}</label>
        <vue-select
          :options="test.options"
          class="input cus-select"
          v-model="test.selected"
        ></vue-select>
      </div>
      <div class="m-b-10 px7 w-100 m-advertiser_input">
        <label class="" for>{{ $t('REPORT_SALES.START_DATE') }}</label>
        <div class="d-flex align-items-center p-relative">
          <Datepicker
            v-model="bannerData.start_date"
            :format="format"
            class="input cus-datepicker"
            :language="languages[currentLang]"
          ></Datepicker>
          <img
            src="/assets/img/icons/calendar-alt-light.svg"
            alt=""
            class="calendar-img"
          />
        </div>
      </div>
      <div class="m-b-10 px7 w-100 m-advertiser_input">
        <label class="" for>{{ $t('REPORT_SALES.END_DATE') }}</label>
        <div class="d-flex align-items-center p-relative">
          <Datepicker
            v-model="bannerData.end_date"
            :format="format"
            class="input cus-datepicker"
            :language="languages[currentLang]"
          ></Datepicker>
          <img
            src="/assets/img/icons/calendar-alt-light.svg"
            alt=""
            class="calendar-img"
          />
        </div>
      </div>
      <div class="m-b-10 px7 w-100 m-days_input">
        <label>{{ $t('GENERAL_SETTING.DAYS') }}</label>
        <vue-select
          :options="test.options"
          class="input cus-select"
          v-model="test.selected"
        ></vue-select>
      </div>
      <div class="m-b-10 px7 w-100 time-dropdown">
        <label>{{ $t('ADS_MANAGEMENT.ADVERTISING_START_TIME') }}</label>
        <div class="d-flex align-items-center p-relative">
          <vue-select
            :options="start_hour.options"
            class="input cus-select"
            v-model="start_hour.selected"
          ></vue-select>
          <img src="/assets/img/icons/clock.png" alt="" class="clock-img" />
        </div>
      </div>
      <div class="m-b-10 px7 w-100 time-dropdown">
        <label>{{ $t('ADS_MANAGEMENT.POST_TIME_PUBLICATION') }}</label>
        <div class="d-flex align-items-center p-relative">
          <vue-select
            :options="end_hour.options"
            class="input cus-select"
            v-model="end_hour.selected"
          ></vue-select>
          <img src="/assets/img/icons/clock.png" alt="" class="clock-img" />
        </div>
      </div>
      <button class="primary-btn add-sm_btn">
        {{ $t('COMMON.ADD') }}
      </button>
      <div class="m-b-10 px7 m-advertising-hours_input">
        <label>{{ $t('ADS_MANAGEMENT.ADVERTISING_HOURS') }}</label>
        <div class="time-range">14:00-16:00 19:00-21:00</div>
      </div>
    </div>
    <div class="form-block m-b-30">
      <div class="m-b-10 pricing-block">
        <div class="d-flex">
          <div class="m-b-10 px7 w-100 m-advertiser_input">
            <label>{{ $t('COMMON.DURATION') }}</label>
            <input type="text" class="input" v-model="bannerData.duration"/>
          </div>
          <button class="primary-btn add-sm_btn">
            {{ $t('COMMON.ADD') }}
          </button>
        </div>
        <div class="d-flex wrap-480">
          <div class="m-b-10 px7 w-100 m-days_input">
            <label>{{ $t('TOURISM_PRODUCT.WAY_PAYMENT') }}</label>
            <vue-select
              :options="test.options"
              class="input cus-select"
              v-model="test.selected"
            ></vue-select>
          </div>
          <div class="m-b-10 px7 w-100 m-days_input">
            <label>{{ $t('ADS_MANAGEMENT.PAYMENT_METHOD') }}</label>
            <vue-select
              :options="test.options"
              class="input cus-select"
              v-model="test.selected"
            ></vue-select>
          </div>
        </div>
      </div>
      <div class="m-b-10 px7 pricing-list">
        <label>{{ $t('ADS_MANAGEMENT.PRICING_LIST') }}</label>
        <div class="pricing-list-block">
          <div class="each-list">
            <div class="d-flex justify-content-between align-items-center">
              <p>14/10/2020</p>
              <p>25/10/2020</p>
              <p>א, ב, ה, ש</p>
              <div>
                <p>14:00-16:00</p>
                <p>19:00-21:00</p>
              </div>
              <p class="px10 color-blue2">{{ $t('ADS_MANAGEMENT.PAID_UP') }}</p>
              <img
                src="/assets/img/icons/pencil-light.svg"
                alt=""
                class="pencil-img"
              />
            </div>
          </div>
          <hr />
          <div class="each-list">
            <div class="d-flex justify-content-between align-items-center">
              <p>14/10/2020</p>
              <p>25/10/2020</p>
              <p>א, ב, ה, ש</p>
              <div>
                <p>14:00-16:00</p>
                <p>19:00-21:00</p>
              </div>
              <p class="px10 color-red4">{{ $t('ADS_MANAGEMENT.PAID_UP') }}</p>
              <img
                src="/assets/img/icons/pencil-light.svg"
                alt=""
                class="pencil-img"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="m-b-10 px7 w-100 file-upload-block p-relative">
        <label>{{ $t('ADS_MANAGEMENT.ADVERTISING') }}</label>
        <div class="set-image_btn upload-setting_btn">
          {{ $t('ADS_MANAGEMENT.IMAGE') }}
        </div>
        <div class="set-video_btn upload-setting_btn">
          {{ $t('ADS_MANAGEMENT.VIDEO') }}
        </div>
        <ImgUpload v-model="bannerData.banner_url" /> 
        <!-- <div class="file-upload">
          <img src="/assets/img/icons/upload.png" alt="" class="upload-img" />
        </div> -->
        <div class="primary-btn primary-btn_sm preview_btn bg-blue2">
          {{ $t('ADS_MANAGEMENT.PREVIEW') }}
        </div>
        <p class="desc">
          *{{ $t('ADS_MANAGEMENT.THE_SIZE_PUBLICATION_IS') }} 1800X250
        </p>
      </div>
    </div>
    <div class="d-flex align-items-center bottom-action-block">
      <button class="primary-btn primary-btn_sm" @click="save()">
        {{ $t('COMMON.SAVE') }}
      </button>
      <button class="primary-btn primary-btn_sm bg-red" @click="closeModal()">
        {{ $t('SUPPLIER_PAYMENT.CANCELATION') }}
      </button>
    </div>
  </modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import TagsBlock from '../TagsBlock.vue';
import ImgUpload from '../ImgUpload.vue';
export default {
  name: "NewEditBannerModal",
  components: {
    TagsBlock,
    ImgUpload
  },
  props: {
    selectedBanner: {
      type: Object
    },
    isEdit: {
      type: Boolean,
      require: true
    }
  },
  computed: {
    ...mapState({
      locations: (state) => state.category_products.locations,
      tags: (state) => state.category_products.tags,
    }),
  },
  async created() {
    if (this.tags.length == 0) {
      await this.getTagsByType({type: 3});
    }

    if (this.locations.length == 0) {
      await this.getLocations();
    }

    if (this.locations.length != 0) {
      this.locationDropdown.options = [];
      this.locations.forEach(el => {
        this.locationDropdown.options.push({
          label: el.location,
          code: el.id
        })
      });
    }
  },
  data() {
    return {
      test: {
        selected: { label: 'הכל', code: 0 },
        options: [{ label: 'הכל', code: 0 }],
      },
      format: 'd MMMM yyyy',
      start_date: '',
      end_date: '',
      start_hour: {
        options: ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"],
        selected: "00:00"
      },
      end_hour: {
        options: ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"],
        selected: "00:00"
      },
      status : {
        options: [
          {
            label: this.$t("COMMON.ACTIVE"),
            code: 1
          },
          {
            label: this.$t("COMMON.INACTIVE"),
            code: 2
          }
        ],
        selected: ""
      },
      bannerData: {},
      locationDropdown: {
        options: [],
        selected: []
      },
      languageDropdown: {
        options: ['HE', 'EN', 'AR'],
        selected: []
      },

    }
  },
  watch: {
    selectedBanner: function(val) {
      if (this.isEdit && Object.keys(val).length !=0) {
        this.bannerData = JSON.parse(JSON.stringify(val));
        this.status.selected = this.status.options.find(el => {
          return el.code == val.status;
        });
        this.locationDropdown.selected = [];
        this.locationDropdown.options.forEach(el => {
          if (val.locations.includes(el.code)) {
            this.locationDropdown.selected.push(el);
          }
        });
        this.start_hour.selected = this.$moment(val.start_date).format('hh:mm');
        this.end_hour.selected = this.$moment(val.end_date).format('hh:mm');
      } else {
        this.bannerData = {};
        this.bannerData.tags = [];
        this.locationDropdown.selected = [];
      }
    }
  },
  methods: {
    ...mapActions("category_products", {
      getLocations: "getLocations",
      getTagsByType: "getTagsByType",
      updateBanner: "updateBanner",
      createBanner: "createBanner",
    }),
    closeModal() {
      this.$modal.hide("NewEditBannerModal");
      this.bannerData = JSON.parse(JSON.stringify(this.selectedBanner));
    },
    async save() {
      let locationsCodes = [];
      this.locationDropdown.selected.forEach(el => {
        locationsCodes.push(el.code);
      });
      
      let params = {
        banner_url: this.bannerData.banner_url,
        duration: this.bannerData.duration,
        end_date: this.$moment(this.bannerData.end_date).format("YYYY-MM-DD"),
        link_url: this.bannerData.link_url,
        name: this.bannerData.name,
        num_bounce: this.bannerData.num_bounce,
        order: this.bannerData.order,
        start_date: this.$moment(this.bannerData.start_date).format("YYYY-MM-DD"),
        status: this.status.selected.code,
        time: this.end_hour.selected,
        language: JSON.stringify(this.bannerData.language),
        locations: JSON.stringify(locationsCodes),
        tags: JSON.stringify(this.bannerData.tags),
      }

      let formData = new FormData();

      for ( var key in params ) {
        formData.append(key, params[key]);
      }
      if (this.isEdit) {
        const id = this.bannerData.id;

        await this.updateBanner({
          id: id,
          params: formData
        });
      } else {
        await this.createBanner({
          params: formData
        });
      }
      this.$emit("finishUpdate");
    }
  }
}
</script>

<style lang="scss">
.newEditBannerModal {
  .vm--modal {
    background-color: #f3f3f3;
    padding: 20px 40px;
    max-height: 95% !important;
    @media screen and (max-width: 480px) {
      padding: 20px 10px;
    }
    label {
      font-weight: 700;
      font-size: 16px;
    }
    .input {
      height: 35px;
      font-size: 16px;
    }
    .cus-select {
      min-width: 170px;
    }
    .form-block {
      display: flex;
      .bounce-btn {
        width: 34px;
        height: 34px;
        img {
          width: 20px;
        }
      }
      .cus-datepicker {
        padding-left: 35px;
      }
      .calendar-img {
        width: 20px;
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
      .time-dropdown {
        max-width: 154px;
        .cus-select {
          min-width: 140px;
          padding-left: 30px;
          .vs__dropdown-menu {
            min-width: 140px;
            max-width: 140px;
          }
          .vs__actions {
            display: none;
          }
        }
        .clock-img {
          position: absolute;
          left: 10px;
          height: 20px;
        }
      }
      .m-days_input {
        max-width: 220px;
        .cus-select {
          min-width: 140px;
          .vs__dropdown-menu {
            min-width: 140px;
            max-width: 140px;
          }
        }
      }

      .m-status_input {
        max-width: 200px;
      }
      .m-bounce-num_input {
        max-width: 226px;
        .input {
          max-width: 160px;
        }
      }
      .m-page_input {
        max-width: 250px;
      }
      .m-advertiser_input {
        max-width: 200px;
      }
      .add-sm_btn {
        height: 34px;
        border-radius: 5px;
        padding: 0 15px;
        width: unset;
        font-size: 16px;
        margin: 24px 0 0;
        transition: 0;
      }
      .m-advertising-hours_input {
        width: 120px;
        .time-range {
          border: 1px solid #b2bbc2;
          background: white;
          width: 100%;
          padding: 5px;
          font-size: 16px;
        }
      }
      .pricing-list-block {
        border: 1px solid #707070;
        background-color: #fff;
        padding: 5px 10px;
        font-size: 14px;
        min-width: 446px;
        p {
          margin: 0 8px;
        }
        .pencil-img {
          width: 15px;
        }
        hr {
          margin: 10px 0;
          border-color: #707070;
        }
      }
      .file-upload-block {
        .file-upload {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #fff;
          height: 117px;
          .upload-img {
            width: 80px;
          }
        }
        .desc {
          font-size: 14px;
          margin: 0;
        }
        .upload-setting_btn {
          position: absolute;
          font-size: 10px;
          padding: 0 10px;
          top: 8px;
        }
        .set-image_btn {
          background-color: #0d3856;
          color: #fff;
          left: 52px;
          width: max-content;
        }
        .set-video_btn {
          left: 7px;
          color: #0d3856;
          background-color: #fff;
          width: max-content;
        }
        .preview_btn {
          position: absolute;
          left: 0px;
          bottom: -90px;
        }
      }
    }

    .primary-btn_sm {
      border-radius: 5px;
      height: 36px;
      max-width: 150px;
      margin: 0 7px;
      font-size: 16px;
    }

    .bottom-action-block {
      margin-bottom: 100px;
    }
  }
}
.ltr-type {
  .set-image_btn {
    left: unset !important;
    right: 52px;
  }
  .set-video_btn {
    left: unset !important;
    right: 7px;
  }
}
</style>

<style lang="scss" scoped>
label {
  display: block;
  margin: 0;
}
@media screen and (max-width: 1250px) {
  .newEditBannerModal .vm--modal .form-block {
    flex-wrap: wrap;
    .m-publication_input {
      max-width: 480px;
    }
  }
}

@media screen and (max-width: 768px) {
  .newEditBannerModal .vm--modal .form-block {
    .add-sm_btn {
      margin: 24px 0 0;
    }
  }
}
@media screen and (max-width: 480px) {
  .newEditBannerModal .vm--modal {
    .form-block {
      .m-status_input,
      .m-advertiser_input,
      .m-page_input,
      .m-days_input {
        max-width: unset;
      }
      .m-bounce-num_input {
        max-width: unset;
        .input {
          max-width: unset;
        }
      }
      .pricing-list-block {
        min-width: unset;
      }
      .each-list > div {
        flex-wrap: wrap;
      }
      .wrap-480 {
        flex-wrap: wrap;
      }
      .file-upload-block .preview_btn {
        bottom: -35px;
      }
    }
  }
}
</style>