<template>
  <div class="form-item p-relative h-100">
    <input
      class="tour-passport__file w-100"
      type="file"
      @change="handleImage"
      accept="image/*"
      ref="uplaod_file"
      multiple="multiple"
    />
    <div
      class="d-flex align-items-center justify-content-center bg-white bdbr-2 h-100"
    >

      <img
        src="/assets/img/icons/upload.png"
        class="upload-icon"
        alt=""
        v-show="!isPreview && !value"
      />
      <img
        :src="value"
        class="preview-img"
        alt=""
        v-show="!isPreview && value"
      />
      <img
        :src="previewImage"
        class="preview-img"
        alt=""
        v-show="isPreview"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImgUpload',
  props:{
    value: {}
  },
  data() {
    return {
      previewImage: '',
      isPreview: false
    }
  },
  methods: {
    handleImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      this.isPreview = true;
      reader.onload = (e) => {
        this.previewImage = e.target.result;
        // this.$emit('input', e.target.result);
      };
      this.$emit('input', this.$refs.uplaod_file.files[0]);
    },
  },
  watch: {
    value: function(val) {
      this.isPreview = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.preview-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>